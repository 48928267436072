<template>
  <div>
    <!-- <page-header-back :backLink="backLink"></page-header-back> -->

    <v-container fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" class="px-0">
                  <v-sheet color="warning" class="text-body-2 white--text text-center py-1 mx-n1">
                    [ 타인의 사진, 음원 등 저작물을 등록할 경우, 저작권법 위반에 해당할 수 있으니 주의해 주세요. ]
                  </v-sheet>
                </v-col>
              </v-row>
            </v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-card-title>
              미디어 등록
            </v-card-title>
            <v-divider/>
            <v-row justify="center">
              <v-col cols="11" class="pb-0">
                  <v-list-item>
                    <v-list-item-title class="mt-3">
                      <v-btn-toggle
                        v-model="toggle"
                        tile
                        color="light-green darken-4"
                        group
                        mandatory
                      >
                        <v-btn large class="mr-3">
                          <v-icon color="primary">mdi-volume-high</v-icon>Audio
                        </v-btn>
                        <v-btn large>
                          <v-icon color="red">mdi-youtube</v-icon>Youtube
                        </v-btn>
                      </v-btn-toggle>
                    </v-list-item-title>
                  </v-list-item>
              </v-col>
              <v-col cols="11" style="align-self: center;text-align:center;" class="py-0">
                  <div v-if="toggle == 1" class="pt-3">
                    <youtube :video-id="youtubeId" ref="youtube" @playing="playing"></youtube>
                  </div>
              </v-col>
              <v-col cols="11">
                <v-form ref="form" lazy-validation>
                  <v-list-item>
                    <v-file-input v-if="toggle == 0"
                      v-model="audioFiles"
                      @change="audioFileChange"
                      prepend-icon="mdi-volume-high"
                      accept="audio/*"
                      :rules="[rule.required]"
                      placeholder="Audio 파일을 선택하세요"
                      counter
                      multiple
                      :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 1"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 1"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ audioFiles.length - 1 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    <v-text-field v-else
                      v-model="youtubeUrl"
                      :rules="[rule.youtube]"
                      placeholder="https://www.youtube.com/watch?v=abCdef00Ghi"
                      prepend-icon="mdi-youtube"
                      @change="onUrlChange"
                    ></v-text-field>

                    <div class="d-none d-md-flex">
                      <audio controls autoplay class="ml-3" id="audioControl" v-if="toggle == 0"></audio>
                    </div>
                  </v-list-item>
                  <div class="text-subtitle-1 pl-4" v-if="toggle == 0">
                    <v-icon>mdi-playlist-play</v-icon> <span class="text-caption">여러개 파일은 drag and drop으로 재생순서를 조정하세요</span>
                    <v-card elevation="0" v-if="audioFilesList.length > 0">

                        <draggable
                          class="list-group"
                          handle=".handle"
                          v-bind="dragOptions"
                          v-model="audioFilesList"
                        >
                          <transition-group type="transition" name="flip-list">

                            <template v-for="(element, index) in audioFilesList">

                              <v-list-item dense
                                :key="element.order"
                                hide-headers
                                style="border-bottom: 1px solid #d3d3d3"
                              >
                                <v-list-item-icon class="handle mr-2" style="cursor: move;align-self: auto;">
                                  <v-icon>mdi-swap-vertical</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>{{ index + 1 }}. {{ element.name }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <!-- <v-divider></v-divider> -->
                                <v-list-item-action>
                                  <v-btn
                                    fab
                                    x-small
                                    color="info"
                                    dark
                                    @click="playAudioFile(index)"
                                  ><v-icon>mdi-play</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </template>
                          </transition-group>

                        </draggable>

                    </v-card>
                  </div>
                  <v-list-item  v-if="toggle == 0">
                    <v-file-input
                      v-model="pdfFile"
                      @change="pdfFileChange"
                      prepend-icon="mdi-file-pdf-box"
                      accept=".pdf"
                      placeholder="PDF 파일을 선택하세요"
                    ></v-file-input>
                    <v-btn text class="ml-3 d-none d-md-flex" @click="previewPdf">
                      <v-icon>mdi-view-carousel-outline</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-select
                      v-model="folder"
                      :items="folders"
                      :rules="[rule.required]"
                      item-text="name"
                      item-value="id"
                      placeholder="시리즈별 폴더를 선택하세요"
                      prepend-icon="mdi-folder"
                      required
                    >
                      <template slot="no-data">
                        <v-alert>등록된 폴더가 없습니다</v-alert>
                      </template>
                    </v-select>
                    <v-btn text class="ml-3" @click="dialog = true">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="mediaTitle"
                      :rules="[rule.required]"
                      placeholder="제목을 입력하세요"
                      prepend-icon="mdi-format-title"
                      required
                    ></v-text-field>
                  </v-list-item>

                </v-form>
              </v-col>
            </v-row>
          </v-card>
          <v-card flat class="mt-5">
            <div v-if="audioUploading" class="caption">
              Audio 파일 uploading...{{ uploadingDesc }}
            </div>
            <v-progress-linear
              :active="audioUploading"
              v-model="progressAudioUpload"
              color="amber"
              height="10"
            ></v-progress-linear>
            <div v-if="pdfUploading" class="caption">
              PDF 파일 uploading...
            </div>
            <v-progress-linear
              :active="pdfUploading"
              v-model="progressPdfUpload"
              color="amber"
              height="10"
            ></v-progress-linear>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                class="mb-5"
                text
                :disabled="waitForSave"
                @click="cancel"
                large
              >목록 보기</v-btn>
              <v-btn
                class="mb-5 mr-2"
                :disabled="waitForSave"
                tile outlined
                color="primary"
                @click="save"
                large
              >저장</v-btn>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
      <add-folder-card
        :dialog="dialog"
        :folders="folders"
        :mediaItemsCnt="mediaItemsCnt"
        @closeDialog="closeDialog"
      ></add-folder-card>

    </v-container>

    <v-snackbar
      v-model="snackbar"
      color="success"
      bottom
      timeout="3000"
    >
      저장되었습니다.
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
// import PageHeaderBack from '../PageHeaderBack'
import AddFolderCard from '@/components/widgets/AddFolderCard'
// import jsmediatags from 'jsmediatags'
import draggable from 'vuedraggable'

export default {
  // components: { PageHeaderBack, ImageUploader, AddFolderCard },
  components: { AddFolderCard, draggable },
  data () {
    return {
      dialog: false,
      mediaTitle: '',
      folder: '',
      dialogFolder: '',
      backLink: '/repo/library',
      toggle: 0,
      youtubeUrl: '',
      // uploadImgTask: '',
      uploadPdfTask: '',
      firebaseImageName: '',
      firebasePdfFileName: '',
      imgUploaded: false,
      progressAudioUpload: 0,
      progressPdfUpload: 0,
      audioUploading: false,
      audioUploaded: false,
      pdfUploading: false,
      pdfUploaded: false,
      pdfFile: null,
      waitForSave: false,
      itemDocid: '',
      rule: {
        required: v => !!v || '필수 항목입니다.',
        youtube: v => {
          const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
          return pattern.test(v) || '올바른 youtube 주소가 아닙니다.'
        }
      },
      folders: [],
      mediaItemsCnt: {},
      snackbar: false,
      youtubeId: '',
      // /////
      audioFiles: [],
      audioFilesList: [],
      totalSec: 0,
      totalSize: 0,
      uploadingDesc: ''
    }
  },
  watch: {
    uploadPdfTask: async function () {
      await this.uploadPdfTask.on('state_changed', sp => {
        this.progressPdfUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
        this.waitForSave = true
      },
      null,
      async () => {
        const downloadURL = await this.uploadPdfTask.snapshot.ref.getDownloadURL()
        if (this.itemDocid) {
          await this.$firebase.firestore().collection('libraries').doc(this.itemDocid).update({
            pdfUrl: downloadURL,
            pdfFilename: this.firebasePdfFileName
          })
        }
        this.pdfUploading = false
        this.pdfUploaded = true
        this.waitForSave = false

        // if (!this.audioUploading && this.audioUploaded) {
        //   this.clearData()
        // }
        // if (!this.audioFile) {
        //   this.clearData()
        // }
      })
    }
  },
  created () {
    this.read()
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    read () {
      this.getFolders()
      this.getMediaCnt()
    },
    getFolders () {
      let academyid = ''
      try {
        academyid = this.$store.state.user.uid
      } catch (e) {
        academyid = localStorage.acid
      }

      this.$firebase.firestore().collection('academies').doc(academyid).collection('folders')
        .orderBy('name')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.folders = []
            return
          }

          this.folders = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              name: item.name
            }
          })
          // console.log('###', JSON.stringify(this.folders))
        })
    },
    async getMediaCnt () {
      const academyid = this.$store.state.user.uid
      await this.$firebase.firestore().collection('academies').doc(academyid)
        .collection('infos').doc('media')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.mediaItemsCnt = {}
          } else {
            this.mediaItemsCnt = sn.data()
            // console.log(this.mediaItemsCnt)
          }
        })
    },
    closeDialog (b) {
      this.dialog = false
    },
    cancel () {
      this.$router.push(this.backLink)
    },
    async save () {
      if (!this.$refs.form.validate()) {
        return
      }

      try {
      // 1. firebase 저장
        const videoId = this.getVideoId(this.youtubeUrl)
        if (this.toggle === 1) {
          if (!videoId) {
            this.$swal.fire({
              icon: 'error',
              title: 'youtube 주소 형식이 아닙니다!'
            })

            return
          }
        }

        const newItem = {
          academy: this.$store.state.user.uid,
          title: this.mediaTitle,
          type: (this.toggle === 0) ? 'A' : 'Y',
          imageUrl: (this.toggle === 0) ? '' : this.getVideoThumbUrl(videoId),
          imageName: '',
          audioFiles: [],
          pdfUrl: '',
          pdfFilename: '',
          pdfFileSize: (this.pdfFile) ? this.pdfFile.size : 0,
          youtubeUrl: (this.toggle === 0) ? '' : this.youtubeUrl,
          duration: (this.toggle === 0) ? this.getDuration() : await this.getVideoDuration(videoId),
          audioFilesSeconds: (this.toggle === 0) ? this.totalSec : 0,
          audioFilesSize: (this.toggle === 0) ? this.totalSize : 0,
          folder: this.folder,
          createdAt: new Date(),
          open: 'N'
        }

        await this.$firebase.firestore().collection('libraries').add(newItem)
          .then(ref => {
            this.itemDocid = ref.id
            // console.log('libraries doc id = ', ref.id)
          })

        // 2. 이미지, audio 파일 upload
        // if ((this.audioFiles.length > 0) && (this.toggle === 0)) this.uploadCoverImg()
        if (this.pdfFile) await this.uploadPdf(this.pdfFile)

        if ((this.audioFiles.length > 0) && (this.toggle === 0)) this.uploadAudio()

        if (this.toggle !== 0) this.clearData()
      } catch (e) {
        this.$swal.fire({
          icon: 'error',
          title: '미디어 등록에 실패했습니다!'
        })
        console.log(e.message)
      }
    },
    clearData () {
      // this.$router.push(this.backLink)

      const audio = document.getElementById('audioControl')
      if (audio) {
        audio.pause()
        audio.currentTime = 0
        audio.src = ''
      }

      this.audioFiles = []
      this.pdfFile = null
      this.youtubeUrl = ''
      this.youtubeId = ''

      this.imgUploaded = false
      this.audioUploaded = false
      this.pdfUploaded = false

      this.firebaseImageName = ''
      this.firebaseAudioFileName = ''
      this.firebasePdfFileName = ''

      this.$refs.form.reset()

      this.snackbar = true
    },
    uploadPdf (file) {
      const fileName = new Date().getTime()
      const extArr = file.name.split('.')
      const ext = '.' + extArr[extArr.length - 1]

      try {
        const storageRef = this.$firebase.storage().ref()

        this.uploadPdfTask = storageRef.child('pdffiles/' + this.$store.state.user.uid + '/' + fileName + ext).put(file)
        this.firebasePdfFileName = fileName + ext
        this.pdfUploading = true
      } catch (e) {
        console.log(e.message)
      }
    },
    async uploadAudio () {
      const insertForm = []
      this.audioUploading = true
      for (let i = 0; i < this.audioFilesList.length; i++) {
        const file = this.audioFiles[this.audioFilesList[i].order - 1]
        const form = {
          title: file.name,
          filename: '',
          size: file.size,
          order: i,
          url: ''
        }
        this.uploadingDesc = '(' + file.name + ')'
        await this.uploadAudioPromise(file)
          .then((res) => {
            form.filename = res.filename
            form.url = res.url
          })
        insertForm.push(form)
      }
      // console.log(insertForm)

      if (this.itemDocid) {
        await this.$firebase.firestore().collection('libraries').doc(this.itemDocid).update({
          audioFiles: insertForm
        })
      }
      this.uploadingDesc = ''
      this.audioUploading = false
      this.audioUploaded = true
      this.waitForSave = false

      if (!this.pdfFile || (!this.pdfUploading && this.pdfUploaded)) {
        this.clearData()
      }
    },
    async uploadAudioPromise (file) {
      return new Promise((resolve, reject) => {
        const filename = new Date().getTime()

        const storageRef = this.$firebase.storage().ref()
        const task = storageRef.child('audiofiles/' + this.$store.state.user.uid + '/' + filename).put(file)

        // Update progress bar
        task.on('state_changed',
          async (snapshot) => {
            this.progressAudioUpload = await Math.floor(snapshot.bytesTransferred / snapshot.totalBytes * 100)
            this.waitForSave = true
          },
          (err) => {
            console.log(err)
            reject(err)
          },
          async () => {
            const downloadURL = await task.snapshot.ref.getDownloadURL()
            resolve({ filename: filename, url: downloadURL })
          }
        )
      })
    },
    getDuration () {
      // const audio = document.getElementById('audioControl')

      // if (!audio.src) return '00:00'

      // const totalsec = Math.floor(audio.duration)
      // const min = this.leadingZeros(Math.floor(totalsec / 60), 2)
      // const sec = this.leadingZeros(totalsec % 60, 2)
      // const mmss = min + ':' + sec
      // return mmss
      if (this.totalSec <= 0) return '00:00'

      const hours = Math.floor(this.totalSec / 3600)
      const resSec = this.totalSec % 3600
      const mins = Math.floor(resSec / 60)
      const secs = resSec % 60

      let ret = ''
      if (hours > 0) {
        ret = String(hours).padStart(2, '0') + ':' + String(mins).padStart(2, '0') + ':' + String(secs).padStart(2, '0')
      } else {
        ret = String(mins).padStart(2, '0') + ':' + String(secs).padStart(2, '0')
      }

      return ret
    },
    async audioFileChange (file) {
      const audio = document.getElementById('audioControl')
      audio.pause()
      audio.currentTime = 0
      audio.src = ''
      // init
      this.totalSec = 0
      this.totalSize = 0

      if (file.length <= 0) {
        this.audioFilesList = []
        return
      }

      this.audioFilesList = this.audioFiles.map((t, index) => {
        return { name: t.name, order: index + 1 }
      })

      this.mediaTitle = file[0].name.substring(0, file[0].name.lastIndexOf('.'))

      // total seconds check
      for (let i = 0; i < this.audioFilesList.length; i++) {
        const file = this.audioFiles[this.audioFilesList[i].order - 1]
        this.totalSize = this.totalSize + file.size

        await this.totalAudioPromise(file)
          .then((res) => {
            this.totalSec = this.totalSec + Math.floor(res)
          })
      }
    },
    async totalAudioPromise (file) {
      return new Promise((resolve, reject) => {
        const audio = new Audio()
        audio.src = URL.createObjectURL(file)
        audio.onloadedmetadata = () => {
          resolve(audio.duration)
        }
      })
    },
    pdfFileChange (file) {
      //
    },
    previewPdf () {
      if (!this.pdfFile) return

      const url = URL.createObjectURL(this.pdfFile)
      window.open(url, 'OpenPDF')
    },
    leadingZeros (n, digits) {
      let zero = ''
      n = n.toString()

      if (n.length < digits) {
        for (let i = 0; i < digits - n.length; i++) { zero += '0' }
      }
      return zero + n
    },
    getVideoId (url) {
      if (!url) return ''

      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },
    async getVideoDuration (vid) {
      if (!vid) return '00:00'

      const r = await this.$http.get('https://www.googleapis.com/youtube/v3/videos', {
        params: {
          id: vid,
          part: 'contentDetails',
          key: 'AIzaSyD1xBdatShxxUPF3_xqFsaoBLuu9TGcVYo'
        }
      })

      try {
        const { body } = r

        const vDur = body.items[0].contentDetails.duration

        const totSeconds = this.$moment.duration(vDur).asSeconds()
        if (totSeconds >= 3600) {
          // HH:MM:SS
          return this.$moment.utc(totSeconds * 1000).format('hh:mm:ss')
        } else {
          // MM:SS
          return this.$moment.utc(totSeconds * 1000).format('mm:ss')
        }
      } catch (e) {
        console.log(e.message)
        return '00:00'
      }
    },
    getVideoThumbUrl (vid) {
      if (!vid) return ''

      // const url = 'https://img.youtube.com/vi/mlJ-1AJ72ak/mqdefault.jpg'
      const url = 'https://img.youtube.com/vi/' + vid + '/default.jpg'
      return url
    },
    onUrlChange () {
      const vid = this.getVideoId(this.youtubeUrl)
      if (!vid) {
        this.$swal.fire({
          icon: 'error',
          title: 'youtube 주소 형식이 아닙니다!'
        })

        return
      }
      this.youtubeId = vid
    },
    playVideo () {
      this.player.playVideo()
    },
    playing () {
      // console.log('we are watching!!!')
    },
    playAudioFile (index) {
      const audio = document.getElementById('audioControl')
      audio.pause()
      audio.currentTime = 0
      audio.src = ''
      if (this.audioFiles.length <= 0) return

      audio.src = URL.createObjectURL(this.audioFiles[this.audioFilesList[index].order - 1])
      audio.load()
    }
  }
}
</script>
